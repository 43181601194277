import './style.css';
import React, { 
    useState, useEffect,useRef 
}                                       from 'react';

import { ReactComponent as Discord }    from '../../icons/discord.svg';
import { ReactComponent as Twitter }    from '../../icons/twitter.svg';

export default (props) => {
   
    const [address, setAddress] = useState(null);
    const [avatar, setAvatar]   = useState(null);
    
    useEffect(() => {
       
       if(props.user?.address){
           
           prepareAddress(props.user.address);
       }
       
    }, [props.user]);
    
    const formatAddress = (address) => {
        
        return address.substring(0,10) + " ...";
    };
    
    const prepareAddress = async (address) => {
      
        try{    
            
            const get_nfd = await fetch("https://api.nf.domains/nfd/v2/address?address="+address+"&limit=1&view=thumbnail", {
                
                method: 'GET',
                headers: {},
            });
      
            const nfd = await get_nfd.json();
            
            const name = nfd[address][0].name;
            const avatar = nfd[address][0].properties.userDefined.avatar;
            
            setAddress(nfd[address][0].name)
            setAvatar(avatar);
        }
        catch(e){
            
            setAddress(formatAddress(address))
        }
    };
    
    return (                 
        <div id="top-bar">            
           
            <div class="block" id="address">
            {
                avatar ? <img src={avatar} id="avatar"/> : <></>
            }
            {
            props.user?.address ? <><span id="address">{address}</span><button class="small-btn" onClick={props.onDisconnect}>disconnect</button></> : <></>
            }
            </div>
        </div>                       
    );
} 