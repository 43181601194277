import './App.css';
import React, { useState, useEffect,useRef }    from 'react';
import BlocksViewer     from "./components/blocks-viewer/blocks-viewer";
import TopBar           from "./components/top-bar/top-bar";
import Home             from "./components/home/home";
import Admin            from "./components/admin/admin";
import Doc              from "./components/doc/doc";
import AddressStats     from "./components/address-activity/address-activity";
import Activity         from "./components/activity/activity";
import Menu             from "./components/menu/menu";
import Api              from "./includes/api"


import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() { 

    const [user, setUser] = useState(null);
     
    useEffect(() => {
             
        loadUser();
        
    }, []);
    
    
    const loadUser = async () => {
        
        const user  = await Api.get("user");
      
        if(user.content?.address){
               
            setUser(user.content);
        }
        else{
            
            setUser(false);
        }
    };
    
    
    const onDisconnect = () => {
        
        setUser(false);
    };
    
    return (
        <>
        
        <header>    
            <TopBar user={user} onDisconnect={onDisconnect}/>
            <div id="logo"></div> 
            <Menu />
        </header>
        <main>
            <BrowserRouter>    
               <Routes>
                   <Route path="/"              element={<Home user={user} onConnect={loadUser}/>} />            
                   <Route path="/pools"         element={<Admin />} />
                   <Route path="/address-stats" element={<AddressStats />} />
                   <Route path="/activity"      element={<Activity page="summary" />} />
                   <Route path="/activity/live" element={<Activity page="live" />} />
                   <Route path="/doc"           element={<Doc />} />
               </Routes>         
            </BrowserRouter>            
        </main> 
        <BlocksViewer />
        
        </>
    );

}

export default App;