import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import Live     from "./live";
import Summary  from "./summary";
import Api      from "../../includes/api"        
 
export default (props) => {
        
    const [loading, setLoading] = useState(false); 
    const [summary, setSummary] = useState([]);
    
    useEffect(() => {
       
       loadSummary();
       
    }, []);
  
  
    const loadSummary = async () => {
        
        const summary = await Api.get("activity-summary");
        
        setSummary(summary.content);
    };
    
    
    const drawEntry = (entry) => {
        
        let res = [];
        console.log(entry.type);
        switch(entry.type){
            
            case "proposed_block":  res = drawProposedBlock(entry); break;
            case "stake":           res = drawStakeAndUnstake(entry); break;
            case "unstake":         res = drawStakeAndUnstake(entry); break;
        }
        
        return res;
    };
    
    
    const drawProposedBlock = (entry) => {
        
        return <div>{entry.ts}</div>;
    };
    
    
    const drawStakeAndUnstake = (entry) => {
        
        return <div>{entry.ts}</div>;
    };
            
            
    return (
        <div id="summary" class="white-block">
        {
            summary.map(entry => {
                
                return drawEntry(entry);
            })
        }
        </div>
    );
}
