// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pools-metrics{
    
    display: flex;
    align-items: flex-start;
    gap: var(--gap);       
    width:100%;

    .pool{

        width:100%;
    }

    .big{

        font-size:1em;
    }
    
    section{
        
        text-align: center;
        margin: 30px 0px;
    }
    
    section:first-of-type{
            
        margin-top:20px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pools/style.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,UAAU;;IAEV;;QAEI,UAAU;IACd;;IAEA;;QAEI,aAAa;IACjB;;IAEA;;QAEI,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;;QAEI;IACJ;AACJ","sourcesContent":["#pools-metrics{\r\n    \r\n    display: flex;\r\n    align-items: flex-start;\r\n    gap: var(--gap);       \r\n    width:100%;\r\n\r\n    .pool{\r\n\r\n        width:100%;\r\n    }\r\n\r\n    .big{\r\n\r\n        font-size:1em;\r\n    }\r\n    \r\n    section{\r\n        \r\n        text-align: center;\r\n        margin: 30px 0px;\r\n    }\r\n    \r\n    section:first-of-type{\r\n            \r\n        margin-top:20px\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
