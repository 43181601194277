import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
        
 
export default (props) => {

    const [pathname, setPathname] = useState("/");
    
    useEffect(() => {
        
        const pathname = window.location.pathname.split("/")[1];
      
        setPathname(pathname);
        
    });
    
    return (
        <>
        <ul id="menu">
            <li> <a href="/"                class={pathname === "" ? "selected" : ""}>Stake & rewards</a></li>
            <li> <a href="/address-stats"   class={pathname === "address-stats" ? "selected" : ""}>Your stats</a></li>
            <li> <a href="/activity"        class={pathname === "activity" ? "selected" : ""}>Pool activity</a></li>
            <li> <a href="/activity">How this works</a></li>
        </ul>
        

        </>
    );
}
