const helpers = {

    formatDate: (ts) => {

        const today         = new Date(Date.now());
        const date          = new Date(parseInt(ts));        
        const is_date_today = today.getDay()+""+today.getMonth() === date.getDay()+""+date.getMonth();
        let res             = new Date(parseInt(ts)).toLocaleString();
        
        if(is_date_today){
           
            const hours     = date.getHours().toString().length === 1 ? "0"+date.getHours() : date.getHours();
            const minutes   = date.getMinutes().toString().length === 1 ? "0"+date.getMinutes() : date.getMinutes();
            res             = "today at "+hours+":"+minutes;
        }
        
        return res;
    }
};

export default helpers;