// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-bar{
       
    height:65px;
    opacity:0.3;   
    display:flex;
    transition: opacity 0.4s; 
    position:absolute;
    top:0px;
    left:auto;
    align-items: center;  
    
    .block{
                
        font-size:1.5em;
        padding:15px;
    }   
           
    #discord{
        
        color:#6056f1
    }
    
    #x{
        
        color:black
    }
    
    #address{
        
        display: flex;
        align-content: center;
        align-items: center;
        gap: 10px;
        font-size:0.90em;
    }
}

#top-bar:hover{
    
    opacity:0.9
}`, "",{"version":3,"sources":["webpack://./src/components/top-bar/style.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,iBAAiB;IACjB,OAAO;IACP,SAAS;IACT,mBAAmB;;IAEnB;;QAEI,eAAe;QACf,YAAY;IAChB;;IAEA;;QAEI;IACJ;;IAEA;;QAEI;IACJ;;IAEA;;QAEI,aAAa;QACb,qBAAqB;QACrB,mBAAmB;QACnB,SAAS;QACT,gBAAgB;IACpB;AACJ;;AAEA;;IAEI;AACJ","sourcesContent":["#top-bar{\r\n       \r\n    height:65px;\r\n    opacity:0.3;   \r\n    display:flex;\r\n    transition: opacity 0.4s; \r\n    position:absolute;\r\n    top:0px;\r\n    left:auto;\r\n    align-items: center;  \r\n    \r\n    .block{\r\n                \r\n        font-size:1.5em;\r\n        padding:15px;\r\n    }   \r\n           \r\n    #discord{\r\n        \r\n        color:#6056f1\r\n    }\r\n    \r\n    #x{\r\n        \r\n        color:black\r\n    }\r\n    \r\n    #address{\r\n        \r\n        display: flex;\r\n        align-content: center;\r\n        align-items: center;\r\n        gap: 10px;\r\n        font-size:0.90em;\r\n    }\r\n}\r\n\r\n#top-bar:hover{\r\n    \r\n    opacity:0.9\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
