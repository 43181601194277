import Nodely   from "./nodely";
import algosdk  from "algosdk";

const apps = JSON.parse(process.env.REACT_APP_CONTRACTS_ID);

const getAccount = async (address) => {
    
    const algod = Nodely.getAlgod();
    const res   = await algod.accountInformation(address).do();
    
    return res;
};


const contracts = {
 
    getStakerAppId: () => {
        
        return apps.staker;
    },
    
    
    getBalancerAppId: () => {
        
        return apps.balancer;
    },
    
    
    getPoolAppId: (index) => {
        
        return apps.pools[index];
    },
 
 
    getPools: () => {
        
        return apps.pools;
    },
     
    // Return the main-pool stake reading the staker contract
    getAppStake: async () => {

        const algod         = Nodely.getAlgod();
        const get_app       = await algod.getApplicationByID(apps.staker).do();    
        const global_state  = get_app.params['global-state'][0];
                    
        return global_state.value.uint;
    },
    
    // Returns the address stake
    getUserStake: async (address) => {
            
        const get_account       = await getAccount(address);
        const get_staker_optin  = get_account["apps-local-state"].filter(entry => entry.id === apps.staker);
        const stake             = get_staker_optin[0] ? get_staker_optin[0]["key-value"][0].value.uint : 0;
        
        return stake;
    },
    
    // Returns the address balance
    getUserBalance: async (address) => {
        
        const get_account   = await getAccount(address);       
        const res           = get_account.amount;
        console.log("balance", get_account);
        return res;
    },
    
    // Returns a pool stake value
    getPoolStake: async(app_id) => {
        
        const algod         = Nodely.getAlgod();
        const get_app       = await algod.getApplicationByID(app_id).do();    
        const global_state  = get_app.params['global-state'][0];
        
        return global_state.value.uint;
    },
    
    // Returns a pool stake value
    getPoolBalance: async(app_id) => {
        
        const address       = algosdk.getApplicationAddress(app_id);
        const get_account   = await getAccount(address);       
        const res           = get_account.amount - get_account["min-balance"];
        
        return res;
    },
    
    // Returns the last block number
    getLastRound: async () => {

        const algod         = Nodely.getAlgod();
        const get_status    = await algod.status().do();    
        const last_round    = get_status["last-round"];
                    
        return last_round;
    }
};

export default contracts;