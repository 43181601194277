
import './style.css';
import React, { useState, useEffect }   from 'react';
import Api                              from "../../includes/api";
import algostack                        from '../../includes/algostack';

export default (props) => {
      
    const [participation_key, setParticipationKey]  = useState(null);    
    
    const connectWith = async (trigger) => {
      
        const addresses = await algostack.client[trigger]();        
        
        if(addresses){
           
            const address   = addresses[0];
            const token     = await Api.get("auth-token", {address:address});
            
            if(token.error === false){

                const txn_prepare  = await algostack.txns.prepareTxn({

                    from:   address, 
                    to:     address, 
                    note:   new Uint8Array(Buffer.from(token.content))
                });

                const txn_unsigned = await algostack.txns.makeTxn(txn_prepare);        
                const txn_signed   = await algostack.txns.signTxns([txn_unsigned]); 
                const txn_base64   = Buffer.from(txn_signed[0]).toString('base64');        
                const connect      = await Api.post("auth-token", {txn:txn_base64});
                               
                localStorage.setItem("user", connect.content);
            }
        }
    };

    return (                 
        <div class="white-block wallets">
            <div class="title">Select a wallet to connect with</div>    
            <div id="wallets-buttons">
                <div><button class="small-btn" onClick={() => connectWith("connectPera")}>PeraWallet</button></div>
                <div><button class="small-btn"  onClick={() => connectWith("connectDefly")}>Defly</button></div>  
            </div>
        </div>                       
    );
} 