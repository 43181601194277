import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import MainBlock                                from "../../elements/main-block/main-block";
        
 
export default (props) => {

    const [show_increase_stake, setShowIncreaseStake]   = useState(false);
    const [show_decrease_stake, setShowDecreaseStake]   = useState(false);
    const [show_rewards,        setShowRewards]         = useState(false);
            
    
    return (
        <>
        <div class="global-status flex">
        
             <div class="global-pools">
                <MainBlock 
                    titleClass      = "pink-main-block-title pool-stake-title" 
                    title           = "PARTICIPANTS"
                    contentClass    = "pink-main-block-content global-pools">
                    
                        5
                    
                </MainBlock>
            </div> 
            
            <div class="global-pools">
                <MainBlock 
                    titleClass      = "pink-main-block-title pool-stake-title" 
                    title           = "PROPOSED BLOCKS"
                    contentClass    = "pink-main-block-content">
                    
                        35
                    
                </MainBlock>
            </div>                        
                                  
        </div>
        
        <div class="global-status flex">                
            <div class="global-pools">
                <MainBlock 
                    titleClass      = "purple-main-block-title pool-stake-title" 
                    title           = "DISTRIBUTED REWARDS"
                    contentClass    = "purple-main-block-content">
                    <div class="big">
                        <AlgoAmount class="icon" value="35000000"/>
                    </div>
                </MainBlock>
            </div>
             
        </div>
        </>
    );
}
