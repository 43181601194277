// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallets{
    
    margin-bottom:100px;
    
    #wallets-buttons{
        
        text-align:center;
        line-height:50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/connect-wallet/style.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;;IAEnB;;QAEI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":[".wallets{\r\n    \r\n    margin-bottom:100px;\r\n    \r\n    #wallets-buttons{\r\n        \r\n        text-align:center;\r\n        line-height:50px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
