import './style.css';

import BlocksViewer                     from "../../components/blocks-viewer/blocks-viewer";
import Stats                            from "../../components/stats/stats";
import Splash                           from "../../components/splash/splash";
import Earnings                         from "../../components/user-earnings/user-earnings";
import Pools                            from "../../components/pools/pools";
import Metrics                          from "../../components/metrics/metrics";
import ConnectWallet                    from "../../components/connect-wallet/connect-wallet";
import TopBar                           from "../../components/top-bar/top-bar";
import FlyingRewards                    from "../../elements/flying-rewards/flying-rewards";
import Api                              from "../../includes/api"
import Socket                           from "../../includes/ws";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useState, useEffect}            from 'react';


export default (props) => {
 
    const [show_splash, setShowSplash]  = useState(false);      
    const [app_stake,   setAppStake]    = useState(null);
    const [app_rewards, setAppRewards]  = useState(null);
    
    useEffect(() => {
             
        listenWs();
        loadAppStake();
        loadAppRewards();
        
    }, []);
    
             
    const listenWs = async () => {            
       
        Socket.listen().onAny((event, ...args) => {           

            // if the action (arg[0]) is stake or unstake, refresh the global pool
            if(["stake", "unstake"].includes(args[0])){

                loadAppStake();
            }
        });                               
    };
    
    const loadAppStake = async () => {
        
        const stakes = await Api.get("pools/stakes");
               
        setAppStake(stakes.content);
    };
    
    const loadAppRewards = async () => {
        
        const rewards = await Api.get("pools/rewards");
        
        setAppRewards(rewards.content)
    };
    
    return (
        <>        
        {   
            props.user && true && app_stake && app_rewards ? 
                <>
                <div id="layout-earnings">
                    <Earnings app_stake={app_stake} app_rewards={app_rewards} user={props.user} />
                    <div id="pools-block">
                        <Pools app_stake={app_stake} app_rewards={app_rewards} />                          
                    </div> 
                </div>                   
                </>
                : ""
        }
        {
            props.user === false && app_stake && app_rewards ?
                <>
                <div id="layout-connect-wallet"><ConnectWallet onConnect={props.onConnect}/></div>
                <div id="layout-earnings"><Pools app_stake={app_stake} app_rewards={app_rewards}/></div>
                </>
                : ""
        }            
                                        
        {show_splash ? <Splash onClose={() => setShowSplash(false)}/> : "" }                
        </>
    );

}

