import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import Live     from "./live";
import Summary  from "./summary";
        
 
export default (props) => {
        
    const [loading, setLoading]   = useState(false); 
 
    
    useEffect(() => {
       
    }, []);
  
    
    return (
        <div id="activity">
            
            <ul id="menu">
                <li><a href="/activity"         class={props.page === "summary" || !props.page ? "selected" : ""}>Summary</a></li>
                <li><a href="/activity/live"    class={props.page === "live" ? "selected" : ""}>Live</a></li>
            </ul>
            
            {props.page === "summary" || !props.page ? <Summary /> : ""}
            {props.page === "live" ? <Live /> : ""}
            
        </div>
    );
}
