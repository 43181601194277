import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import MainBlock                                from "../../elements/main-block/main-block";
        
 
export default (props) => {

    const [show_increase_stake, setShowIncreaseStake]   = useState(false);
    const [show_decrease_stake, setShowDecreaseStake]   = useState(false);
    const [show_rewards,        setShowRewards]         = useState(false);
            
    
    return (
        <>
        <div class="flex" id="pools-metrics">        
           
            <div class="pool">
                <MainBlock 
                    titleClass      = "pink-main-block-title pool-stake-title" 
                    title           = "STAKING POOL"
                    contentClass    = "pink-main-block-content">
                    
                    <section>
                      CURRENT LIQUIDITY
                    <div class="big">                          
                        <AlgoAmount format="int" class="icon" value={props.app_stake}/>
                    </div>
                    </section>
                    
                    <section>
                    PROPOSED BLOCKS
                    <div class="big">                          
                        <AlgoAmount format="int" class="icon" value="12"/>
                    </div>
                    </section>
                    
                    <section>
                    EARNED REWARDS
                    <div class="big">                          
                        <AlgoAmount format="int" class="icon" value="12"/>
                    </div>
                    </section>
                    
                </MainBlock>
            </div>                                

            <div class="pool">
                <MainBlock 
                    titleClass      = "purple-main-block-title pool-stake-title" 
                    title           = "REWARDS POOL"
                    contentClass    = "purple-main-block-content global-pools">
                   
                    
                    
                    <section>
                    CURRENT LIQUIDITY
                    <div class="big">                                
                        <AlgoAmount format="int" class="icon" value={props.app_rewards}/>
                    </div>
                    </section>
                    
                    <section>
                    FROZEN REWARDS
                    <div class="big">                                
                        <AlgoAmount format="int" class="icon" value={props.app_rewards}/>
                    </div>
                    </section>
                    
                    <section>
                    PROPOSED BLOCKS
                    <div class="big">                                
                        <AlgoAmount format="int" class="icon" value={props.app_rewards}/>
                    </div>
                    </section>
                    
                    <section>
                    EARNED REWARDS
                    <div class="big">                                
                        <AlgoAmount format="int" class="icon" value={props.app_rewards}/>
                    </div>
                    </section>
                    
                </MainBlock>
            </div>  
                 
        </div>
        </>
    );
}
