import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import MainBlock                                from "../../elements/main-block/main-block";
import UserAddStake                             from "../../components/user-add-stake/user-add-stake";
import UserRemoveStake                          from "../../components/user-remove-stake/user-remove-stake";
import UserRewards                              from "../../components/user-rewards/user-rewards";
import Contracts                                from "../../includes/contracts";
import Socket                                   from "../../includes/ws";
import algosdk, {Transaction}                   from "algosdk";
import algostack                                from '../../includes/algostack';        
import FadeIn                                   from 'react-fade-in';


export default (props) => {

    const [show_increase_stake, setShowIncreaseStake]   = useState(false);
    const [show_decrease_stake, setShowDecreaseStake]   = useState(false);
    const [show_rewards,        setShowRewards]         = useState(false); 
 
    const abi_staker                                    = JSON.parse(process.env.REACT_APP_ABI_STAKER);       
    
    const calculateTotalRewards = (user) => {
        
        console.log(user);
        const frozen    = user.frozen_rewards ?? 0;
        const claimable = user.claimable_rewards ?? 0;
        
        return parseInt(frozen) + parseInt(claimable);
    }; 
        
    const showIncreaseStake = async () => {               
        
        setShowIncreaseStake(true);
        hideDecreaseStake();
        hideRewards();
    };
    
    const showDecreaseStake = () => {
        
        setShowDecreaseStake(true); 
        hideIncreaseStake();
        hideRewards();               
    };
    
    const hideIncreaseStake = () => {
                
        setShowIncreaseStake(false);
    };
    
    const hideDecreaseStake = () => {
                
        setShowDecreaseStake(false);
    };
    
    const showRewards = () => {
        
        setShowRewards(true);
        hideDecreaseStake();
        hideIncreaseStake();        
    };
    
    const hideRewards = () => {
        
        setShowRewards(false);
    };
    
    const getStakePercent = (user_stake, app_stake) => {
        
        let percent = (100 / app_stake) * user_stake;        
        let x       = 2;
        
        while(percent.toPrecision(x) == 0){ 
    
            x++; 

            if(x > 8) break; 
        }
        
        const format_percent = new Number(percent.toPrecision(x));
        
        return format_percent.toString()
    };
    

    
    const onStake = async (stake_value) => {
                               
        const address           = props.user.address; 
        const composer          = new algosdk.AtomicTransactionComposer();
        const contract          = new algosdk.ABIContract(abi_staker); 
        const suggestedParams   = await algostack.txns.algod.getTransactionParams().do();
        suggestedParams.fee     = suggestedParams.minFee * 5;
        suggestedParams.flatFee = true;     
        const staker_app_id     = Contracts.getStakerAppId();
        const pay_txn_prepare   = algosdk.makePaymentTxnWithSuggestedParamsFromObject({

            from:   address, 
            to:     algosdk.getApplicationAddress(staker_app_id),
            amount: parseInt(stake_value),
            note:   new Uint8Array(Buffer.from("milkshake.fi: stake #84")),
            suggestedParams
        });      
        const signingFunction   = async (unsigned_txns) => {
                              
           return await algostack.txns.signTxns(unsigned_txns);
        }            

        const get_pool = await Api.get("user/pool-to-stake-in", {amount:stake_value});               

        if( !get_pool.error && get_pool.content !== null){ 

            const pool_index = get_pool.content;

            composer.addMethodCall({

                appID:      staker_app_id,
                method:     contract.getMethodByName('stake'),      
                sender:     address, 
                signer:     signingFunction,                
                methodArgs: [
                    {                        
                        txn:      pay_txn_prepare, 
                        signer:   signingFunction
                    },
                    pool_index
                ], 
                appForeignApps:[

                    Contracts.getBalancerAppId(),
                    Contracts.getPoolAppId(pool_index)
                ],     
                suggestedParams: suggestedParams,
            });               

            const result = await composer.execute(algostack.txns.algod, 100);

            //console.log(result.methodResults[0].returnValue);
            //console.log(result.methodResults[0].txID);          
        } 
          
    };
    
    const onUnstake = async (unstake_value) => {
              
        const user = await Api.get("user");       
        
        if(user.content && user.content?.address){
            
            const address           = user.content.address;                                                                 
            const composer          = new algosdk.AtomicTransactionComposer();
            const contract          = new algosdk.ABIContract(abi_staker); 
            const params            = await algostack.txns.algod.getTransactionParams().do();
            params.fee              = params.minFee * 3;
            params.flatFee          = true;     
            const staker_app_id     = Contracts.getStakerAppId();      
            const signingFunction   = async ( unsigned_txns) => {

                return await algostack.txns.signTxns(unsigned_txns);
            }            
            
            const txn = {
                
                appID:          staker_app_id,
                method:         contract.getMethodByName('unstake'),      
                sender:         address, 
                signer:         signingFunction,  
                methodArgs:     [unstake_value], 
                appForeignApps: [
                    
                    Contracts.getBalancerAppId(),
                    Contracts.getPoolAppId(0)
                ],     
                suggestedParams: params,
            };
            
            const prepare_group = await Api.post("user/prepare-unstake", {amount:unstake_value, txn:txn});
 
            if( !prepare_group.error ){
                             
                const unsigned_txns                 = [];
                const prepare_liquidity_unsigned    = prepare_group.content.group_unsigned;
                const prepare_liquidity_signed      = prepare_group.content.group_signed;
                const user_app_call_unsigned        = prepare_group.content.unsigned_app_call;
                
                // Decode the eventual unsigned txns that prepare the liquidity
                for(const unsigned of prepare_liquidity_unsigned){
                    
                    unsigned_txns.push(algosdk.decodeUnsignedTransaction(
                        
                        Buffer.from(unsigned, 'base64')
                    ));
                }
                
                // Decode the user unsigned app call (the one the wallet has to sign)
                // Add it to the unsigned_txns array.
                unsigned_txns.push(algosdk.decodeUnsignedTransaction(
                        
                    Buffer.from(user_app_call_unsigned, 'base64')
                ));                               
                 
                // The wallet needs the whole group of the unsigned txns to 
                // verify the integrity of the txn the user needs to sign (the app call),                
                const sign = await algostack.txns.signTxns(unsigned_txns);
                
                // Retrieve the txn that was just signed by the user (last txn from the group)
                const signed_user_app_call = sign.pop()
                
                // The other signed txns of the group were converted to objects when 
                // doing the back->front trip, they must be reconverted to uint8array.
                const signed_group_to_array = prepare_liquidity_signed.map( signed_txn => Object.values(signed_txn) );
                const signed_group          = signed_group_to_array.map( values => new Uint8Array(values));
                
                // Finaly add the user signed txn to the signed group
                signed_group.push(signed_user_app_call);
                
                const tx_id     = await algostack.txns.algod.sendRawTransaction(signed_group).do();             
                const results   = await algosdk.waitForConfirmation(algostack.txns.algod, tx_id.txId, 100);
    
            }
            
        }    
    };
    
    
    const onClaimRewards = async (claim_amount) => {
        
        const prepare_group = await Api.post("user/claim-rewards", {amount:claim_amount});
        
    };


    return (
        <>                             
        <div class="user-block">
            <MainBlock 
                titleClass      = "pink-main-block-title pool-stake-title" 
                title           = "YOUR STAKE"
                contentClass    = "pink-main-block-content"
            >
            <div class="big">
                <AlgoAmount class="icon" value={props.user.stake}/>                
            </div>
            <div class="stake-percent">{getStakePercent(props.user.stake, props.app_stake)}% of the staking pool</div>
            <div></div>
            </MainBlock>
            <div class="user-panel flex">
                <div class={show_increase_stake ? "action action-selected" : "action"} onClick={showIncreaseStake}>STAKE</div>
                <div class={show_decrease_stake ? "action action-selected" : "action"} onClick={showDecreaseStake}>UNSTAKE</div>
                <div class="action details-icon"></div>
            </div>
            {
                show_increase_stake ? <FadeIn><UserAddStake onClose={hideIncreaseStake} user-stake={props.user.stake} onStake={onStake}/></FadeIn> : ""
            }
            {
                show_decrease_stake ? <FadeIn><UserRemoveStake onClose={hideDecreaseStake} user-stake={props.user.stake} onUnstake={onUnstake}/></FadeIn> : ""
            }
        </div>
       
         <div class="user-block">
            <MainBlock 
                titleClass      = "purple-main-block-title pool-stake-title" 
                title           = "YOUR REWARDS"
                contentClass    = "purple-main-block-content">
                <div class="big"><AlgoAmount class="icon" value={calculateTotalRewards(props.user)}/></div> 
                <div class="stake-percent">{getStakePercent(calculateTotalRewards(props.user), props.app_rewards)}% of the rewards pool</div>
            </MainBlock>            
            <div class="user-panel flex">
                <div class={show_rewards ? "action action-purple action-purple-selected" : "action action-purple"} onClick={showRewards}>CLAIM</div>                
                <div class="action action-purple details-icon-purple"></div>
            </div>
            {
                show_rewards ? 
                <FadeIn>
                <UserRewards 
                    onClose             = {hideRewards} 
                    claimable_rewards   = {props.user.claimable_rewards}
                    frozen_rewards      = {props.user.frozen_rewards}
                    onClaim             = {onClaimRewards}/>
                </FadeIn> 
                : ""
            }
        </div>                               
        </>
    );
}
