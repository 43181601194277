import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Loader                                   from "../../elements/loaders/layers";
import Modal                                    from "../../elements/modal/modal";
import algosdk, {Transaction}                   from "algosdk";
        
 
export default (props) => {
            
    const [activity, setActivity]   = useState([]); 
    const [modal,   setModal]     = useState(null);
    
    useEffect(() => {    

        
    }, []);

    const showModal = (e, title, content) => {
        
        e.preventDefault();
        
        setModal({title:title, content:content});               
    };
    
    return (            
        <div id="address-stats"> 
            {modal ? <Modal onClose={(e) => setModal(null)} title={modal.title} content={modal.content}></Modal> : "" }
            <div class="white-block">
            <table>
                <tr>
                    <td>Claimable rewards</td>
                    <td><AlgoAmount icon-width="15px" value={props.data.claimable_rewards ?? 0} /></td>
                </tr>
                <tr>
                    <td class="frozen flex">
                        Frozen rewards
                        <div    class="help"
                                onClick={(e)=>showModal(e, "Frozen rewards",
                                    `Earned rewards are frozen for 30 days into the rewards pool. 
                                    During this period, they actively participate in the 
                                    consensus to generate additional rewards. 
                                    After the holding period ends, you will be free to claim them 
                                    or allow them to remain in the rewards pool where they 
                                    will continue to participate in the consensus 
                                    and accumulate more rewards over time, 
                                    helping you maximize your earnings.`)}>
                        </div>  
                    </td>
                    <td class="frozen"><AlgoAmount icon-width="15px" value={props.data.frozen_rewards ?? 0} /></td>
                </tr>
                <tr>
                    <td class="flex">Will unfreeze in 24h
                        
                        <div    class="help"
                                onClick={(e)=>showModal(e, "Unfreeze in 24h",
                                    `These rewards will unfreeze and become claimable 
                                    within the next 24 hours. After unfreezing, 
                                    you can claim them or leave them into the rewards pool to continue 
                                    participating in the consensus and earning additional rewards.`)}>
                        </div>                        
                    </td>
                    <td><AlgoAmount icon-width="15px" value={props["unfreeze-rewards"] ?? 0} /></td>
                </tr>
                
                <tr>
                    <td class="flex">Total earned from staking pool
                        <div    class="help"
                                onClick={(e)=>showModal(e, "Total earned from staking pool",
                                    `This is the total amount of rewards you have 
                                    earned from staking your tokens in the 
                                    staking pool since the beginning. It includes 
                                    all rewards, both claimed and unclaimed, 
                                    accumulated through your participation in the consensus.`)}>
                        </div>   
                    </td>
                    <td><AlgoAmount icon-width="15px" value={props.data.earned_from_pool ?? 0} /></td>
                </tr>
                <tr>
                    <td class="flex">Total earned from rewards staking
                    <div    class="help"
                                onClick={(e)=>showModal(e, "Total earned from rewards staking",
                                    `This is the total amount of rewards generated 
                                    from the rewards pool since the beginning. 
                                    It includes all rewards, both claimed and unclaimed, 
                                    that have been earned by reinvesting your rewards 
                                    into the consensus.`)}>
                        </div>   
                    </td>
                    <td><AlgoAmount icon-width="15px" value={props.data.earned_from_rewards ?? 0} /></td>
                </tr>
                <tr>
                    <td>Total claimed rewards</td>
                    <td><AlgoAmount icon-width="15px" value={props.data.claimed ?? 0} /></td>
                </tr>
            </table>
            </div>
        </div>                 
    );
}
