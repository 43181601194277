import './App.css';
import React, { useState, useEffect,useRef }    from 'react';
import BlocksViewer     from "./components/blocks-viewer/blocks-viewer";
import TopBar           from "./components/top-bar/top-bar";
import Home             from "./components/home/home";
import Admin            from "./components/admin/admin";
import Doc              from "./components/doc/doc";
import AddressStats     from "./components/address-activity/address-activity";
import Activity         from "./components/activity/activity";
import Menu             from "./components/menu/menu";


import { WalletProvider, useInitializeProviders, PROVIDER_ID } from '@txnlab/use-wallet'
import { DeflyWalletConnect } from '@blockshake/defly-connect'
import { PeraWalletConnect } from '@perawallet/connect'
import { DaffiWalletConnect } from '@daffiwallet/connect'
import LuteConnect from 'lute-connect'

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() { 

    return (
        <>
      
        <header>    
            <div id="logo"></div> 
            <Menu />
        </header>
        <main>
            <BrowserRouter>    
               <Routes>
                   <Route path="/"              element={<Home />} />            
                   <Route path="/pools"         element={<Admin />} />
                   <Route path="/address-stats" element={<AddressStats />} />
                   <Route path="/activity"      element={<Activity page="summary" />} />
                   <Route path="/activity/live" element={<Activity page="live" />} />
                   <Route path="/doc"           element={<Doc />} />
               </Routes>         
            </BrowserRouter>            
        </main> 
        <BlocksViewer />
        <footer>        
            <div id="description">
                <div>Milkshake.fi is an Algorand consensus participation pooling service.</div> 
                <div>Managed by TC: <a href="https://www.societe.com/societe/monsieur-thomas-collet-509790739.html">509 790 739 00031</a></div>
                <div>TVA: FR01509790739</div>
            </div>
        </footer>
        </>
    );

}

export default App;