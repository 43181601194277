// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.algo-amount{
    
    align-items: baseline;
    gap:5px;
    
    .algorand-icon{

        fill:currentColor;
        width:13px;
        white-space: nowrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/elements/algo-amount/style.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,OAAO;;IAEP;;QAEI,iBAAiB;QACjB,UAAU;QACV,mBAAmB;IACvB;AACJ","sourcesContent":[".algo-amount{\r\n    \r\n    align-items: baseline;\r\n    gap:5px;\r\n    \r\n    .algorand-icon{\r\n\r\n        fill:currentColor;\r\n        width:13px;\r\n        white-space: nowrap;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
