import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Toggle                                   from "../../elements/toggle/toggle";
import Loader                                   from "../../elements/loaders/layers";
import Socket                                   from "../../includes/ws";
import AddressStats                             from "./address-stats";
import algosdk, {Transaction}                   from "algosdk";
        
 
export default (props) => {
        
    const [loading, setLoading]                 = useState(false); 
    const [user,     setUser]                   = useState({});
    const [view,     setView]                   =  useState(["stake", "unstake", "earn", "claim"]);
    const [activity, setActivity]               = useState([]); 
    const [proposed_blocks, setProposedBlocks]  = useState({}); 
    
    useEffect(() => {    
                       
        listenWs();
        loadView();
        
    }, []);
    
    // Reload the address activity when the view changes.
    useEffect(() => {
        
        if(view.length > 0){
            
            loadActivity();
        }
        else{
            
            setActivity([]);
        }
        
    }, [view]);
 
    // Load user's stake from chain
    const loadActivity = async () => {               
                           
        setLoading(true);

        const get_activity = await Api.get("address-activity", {address:user.address, view:view});

        setLoading(false);

        setActivity(get_activity.content.activity);
        setProposedBlocks(get_activity.content.blocks);
        
    };
    
    const listenWs = async () => {
        
        const user  = await Api.get("user");
        
        if(user.content && user.content?.address){
            
            setUser(user.content);
        }        
       
        Socket.listen().onAny((event, ...args) => {
     
            if(event === user.content.address){

               // If the event is the current user's address, refresh its stake
               loadActivity();
            }   

        });                               
    };           
    
    const loadView = () => {
        
        const params   = new URLSearchParams(window.location.search)
        const get_view = params.get("view") ? params.get("view").split(",") : null;
        
        if(get_view){
            
            setView(get_view);           
        }
    }
               
    const getState = (view, mode) => {
        
        const res = view.includes(mode) ? "on" : "off"                
        
        return res;
    };
    
    const onChangeView = (state, id) => {
              
        if(state === "on"){
            
            view.push(id);
            
            setView([...view]);
        }
        else if(state === "off"){
            
            const remove_state_from_view = view.filter(item => item !== id);
                    
            setView(remove_state_from_view);
        }
    };        
   
    const formatDate = (ts) => {
        
        return new Date(parseInt(ts)).toLocaleString();
    };
    
    const firstLetterUpperCase = (str) => {
        
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    
    const formatTxnId = (txn_id) => {
        
        return txn_id.substring(0, 10);
    };
    
    const drawEntry = (entry) => {
        
        const res = [];
        
        if(entry.type === "action"){
            
            res.push(drawActionEntry(entry));
        }        
        else if(entry.type === "earn"){
            
            res.push(drawEarnEntry(entry));
        }
        else if(entry.type === "claim"){
            
            res.push(drawClaimEntry(entry));
        }
        
        return res;
    };
    
    const drawActionEntry = (entry) => {
        
        return  <tr>
                    <td class="type">
                        {firstLetterUpperCase(entry.action)}
                        <div class="action-amount"><AlgoAmount icon-width="13px" value={entry.amount} /></div>
                    </td>
                    <td>
                        <div class="details-main">                            
                            <div class="date">{formatDate(entry.ts)}</div>
                        </div>
                        <div class="details-sub">
                            <div>Block: {entry.block}</div>
                            <div>Transaction: {formatTxnId(entry.tx_id)} ...</div>
                        </div>
                    </td>
                </tr>
    };
        
    const drawEarnEntry = (entry) => {
        
        const rewards                   = entry.rewards.split(".");
        const rewards_in_microalgos     = rewards[0];
        const rewards_extra_decimals    = rewards[1];
        const is_frozen                 = entry.unfreeze_ts > Date.now();
        
        return  <tr>
                    <td class={is_frozen ? "type frozen" : "type"}>
                        Earned rewards
                        <div class="action-amount"><AlgoAmount icon-width="13px" value={rewards_in_microalgos} /></div></td>
                    <td>
                        <div class="details-main">                            
                            <div class="date">{formatDate(entry.ts)}</div>                            
                        </div>
                        <div class="details-sub">
                            <div class="frozen">Unfreeze: {formatDate(entry.unfreeze_ts)}</div>
                            <div>Block: {entry.block}</div>                            
                            <div class="flex">Block rewards: <AlgoAmount icon-width="10px" value={proposed_blocks[entry.block].rewards} /></div>
                        </div>
                    </td>
                </tr>
    };
    
    
    const drawClaimEntry = (entry) => {
        
        return  <tr>
                    <td class="type">
                        Claimed rewards
                        <div class="action-amount"><AlgoAmount icon-width="13px" value={entry.amount} /></div>
                    </td>
                    <td>
                        <div class="details-main">                            
                            <div class="date">{formatDate(entry.ts)}</div>
                        </div>
                        <div class="details-sub">
                            <div>Block: {entry.block}</div>
                            <div>Transaction: {formatTxnId(entry.tx_id)} ...</div>
                        </div>
                    </td>
                </tr>
    };
    
    return (
        <div id="address-activity">
            <div id="user-stats"><AddressStats data={user} /></div>
            
            <div class="white-block" id="details">
                <table> 
                <tr>
                    <td colspan="3" id="td-toggles">
                    <div class="flex" id="toggles">
                        <Toggle onChange={onChangeView} id="stake" state={getState(view, "stake")}>Stake</Toggle>
                        <Toggle onChange={onChangeView} id="unstake" state={getState(view, "unstake")}>Unstake</Toggle>
                        <Toggle onChange={onChangeView} id="earn" state={getState(view, "earn")}>Received rewards</Toggle>
                        <Toggle onChange={onChangeView} id="claim" state={getState(view, "claim")}>Claimed rewards</Toggle>
                    </div>
                    </td>
                </tr>
                {
                    activity.map(entry => {

                        return drawEntry(entry)
                    })
                }            
                </table> 
            </div>
            
            {loading ? <Loader /> : ""}
        </div>
    );
}
